import { ethereumTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    lpAddress: '0x7Eb8d6CfbC853d55C2953E617f787DDDEe5276dA',
    token0: ethereumTokens.hydx,
    token1: ethereumTokens.weth,
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 2,
    lpAddress: '0xb334Ede09DC5866A18e5Fd9954C81669277a081D',
    token0: ethereumTokens.usdc,
    token1: ethereumTokens.weth,
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 3,
    lpAddress: '0xA266d5d4AECBc9d3d768b908ae7Ba63BeaB0C0F2',
    token0: ethereumTokens.weth,
    token1: ethereumTokens.usdt,
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 4,
    lpAddress: '0xe6a490caFCaddcE8A965d91AED1d75fcD995c867',
    token0: ethereumTokens.hydx,
    token1: ethereumTokens.usdt,
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 5,
    lpAddress: '0x385003b34445DD32947ED1bF9f3A24C9ba587591',
    token0: ethereumTokens.allusdt,
    token1: ethereumTokens.usdt,
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 6,
    lpAddress: '0x11C0463291CAA713CE7767b804f6fc55BA2829E5',
    token0: ethereumTokens.allusdt,
    token1: ethereumTokens.usdc,
    feeAmount: FeeAmount.LOW,
  },
])

const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'HYDX',
    lpAddress: '0xA0bE76135289aEB5781923eE0d47Ca5527821F59',
    token: ethereumTokens.syrup,
    quoteToken: ethereumTokens.weth,
  },
  {
    pid: 3,
    lpSymbol: 'ETH-HYDX LP',
    lpAddress: '0x4590F5da2284f7B1C76A1E46BcB3ABE5C61E2BF7',
    quoteToken: ethereumTokens.weth,
    token: ethereumTokens.hydx,
  },
  {
    pid: 4,
    lpSymbol: 'ETH-USDC LP',
    lpAddress: '0x43bfE20190611FB621b72bd0624340339D5BBEb1',
    quoteToken: ethereumTokens.weth,
    token: ethereumTokens.usdc,
  },
  {
    pid: 5,
    lpSymbol: 'ETH-USDT LP',
    lpAddress: '0xf75D9549Eea9D922A47846959cd33456CD492db6',
    quoteToken: ethereumTokens.weth,
    token: ethereumTokens.usdt,
  },
  {
    pid: 6,
    lpSymbol: 'HYDX-USDT LP',
    lpAddress: '0x568BF86e8F0cADAdFBf35baECAc552faa2a98435',
    quoteToken: ethereumTokens.usdt,
    token: ethereumTokens.hydx,
  },
  {
    pid: 7,
    lpSymbol: 'AUSDT-USDT LP',
    lpAddress: '0xCEcad8597834D86d9CeF25A1E79A68C151Dd8f2C',
    quoteToken: ethereumTokens.usdt,
    token: ethereumTokens.allusdt,
  },
  {
    pid: 8,
    lpSymbol: 'AUSDT-USDC LP',
    lpAddress: '0x527ff3cAb2489170D6286528D41Da31A1D61Ad29',
    quoteToken: ethereumTokens.usdc,
    token: ethereumTokens.allusdt,
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
