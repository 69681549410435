import { ethereumTokens } from '@pancakeswap/tokens'
import { getAddress } from 'viem'

import { PoolCategory, SerializedPool } from '../../types'

export const livePools: SerializedPool[] = [
  {
    sousId: 0,
    stakingToken: ethereumTokens.hydx,
    earningToken: ethereumTokens.hydx,
    contractAddress: '0xDa4A7cB168362eE4A8e19129bc2283503196d1Bb',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
  {
    sousId: 300,
    stakingToken: ethereumTokens.allusdt,
    earningToken: ethereumTokens.hydx,
    contractAddress: '0x3126cd5167C8DD0181140555B500C614a61D56E5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01',
    isFinished: false,
  },
].map((p) => ({
  ...p,
  contractAddress: getAddress(p.contractAddress),
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
export const finishedPools: SerializedPool[] = []

export const pools: SerializedPool[] = [...livePools, ...finishedPools]
